import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface IProps {
  containerStyle?: any;
  heading: string;
  viewAll?: boolean;
  onPress?: () => void;
}

const AppHeading: React.FC<IProps> = ({
  containerStyle,
  heading,
  viewAll = true,
  onPress,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      <Typography variant="h2" sx={style.heading}>
        {heading}
      </Typography>
      {viewAll && (
        <Button
          onClick={onPress}
          sx={{
            color: theme.palette.primary.main,
            textTransform: "none",
            fontSize: "0.875rem",
          }}
        >
          View all
        </Button>
      )}
    </Box>
  );
};

export default AppHeading;
const style = {
  heading: {
    fontWeight: "700",
    fontFamily: "Spectral, serif",
    fontSize: {
      xs: "1.5rem",
      sm: "2rem",
      md: "2.5rem", 
    
    },
    textAlign: "center",
    letterSpacing: "1px",
    my: 1,
  },
};
