import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/reduxStore.ts";
import { ProductData } from "../services/product/types.ts";

type IProps = {
  onPress: () => void;
  src: string;
  product: ProductData;
  onPressAdd: () => void;
  onPressDec: () => void;
  onDelete: () => void;
  checked: boolean;
  onCheck: () => void;
};

const ProductCardHorizontal: React.FunctionComponent<IProps> = ({
  onPressDec,
  src,
  product,
  onPressAdd,
  onDelete,
}) => {
  const cartData = useSelector((state: StoreState) => state.cart);
  const [quantity, setQuantity] = useState<number>(0);
  const [isProductInCart, setIsProductInCart] = useState<
    { productData: ProductData; quantity: number } | undefined
  >(undefined);

  useEffect(() => {
    const index = cartData.cartProducts.findIndex(
      (item) => item.productData.id === product.id
    );
    if (index > -1) {
      setIsProductInCart(cartData.cartProducts[index]);
      setQuantity(cartData.cartProducts[index].quantity);
    } else setIsProductInCart(undefined);
  }, [cartData.cartProducts, product.id]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "80px 4fr 1fr 1fr 1fr 50px", // Define your grid columns
        alignItems: "center",
        gap: 2,
        padding: 2,
        overflow:"hidden",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Product Image */}
      <Box
        component="img"
        src={src}
        alt={product.name}
        sx={{
          width: 80,
          height: 80,
          borderRadius: 1,
          objectFit: "cover",
        }}
      />

      {/* Product Name and Description */}
      <Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", fontFamily: "'Spectral', serif",width:"400px" }}
        >
          {product.name}
        </Typography>
        <Box>

        <Typography
          variant="body2"
          sx={{
            fontFamily: "'Spectral', serif",
            color: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {product.description}
        </Typography>
        </Box>
      </Box>

      {/* Product Price */}
      <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
        ${product.price.toFixed(2)}
      </Typography>

      {/* Quantity Selector */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton onClick={onPressDec} size="small">
          <RemoveIcon />
        </IconButton>
        <TextField
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
          sx={{
            width: 50,
            marginX: 1,
          }}
          inputProps={{
            min: 1,
            style: {
              textAlign: "center",
              padding: "6px 8px",
            },
          }}
        />
        <IconButton onClick={onPressAdd} size="small">
          <AddIcon />
        </IconButton>
      </Box>

      {/* Total Price */}
      <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
        ${(product.price * quantity).toFixed(2)}
      </Typography>

      {/* Delete Button */}
      <IconButton onClick={onDelete} sx={{ color: "#b7472a" }}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default ProductCardHorizontal;
