import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Button,
  Container,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { useDispatch, useSelector } from "react-redux";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { ProductData } from "../services/product/types.ts";
import { removeItemFromCart } from "../redux/cart/actions.ts";
import images from "../constant/images.ts";
import CartProduct from "../commponents/CartProduct.tsx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useNavigation } from "react-router-dom";

const Cart = () => {
  const theme = useTheme();
  const cartData = useSelector((state: StoreState) => state.cart);
  const userData = useSelector((state: StoreState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<StoreDispatch>();

  const onCheckOut = () => {
    navigate("/checkOut");
  };

  const removeItem = (product: ProductData) => {
    dispatch(removeItemFromCart({ productData: product }));
  };

  return (
    <>
      <Container sx={{ py: 2 }}>
        <Box sx={{ paddingBottom: 12 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#b7472a" }}>
              <TableRow>
                <TableCell sx={style.tHead}>Product Name</TableCell>
                <TableCell sx={style.tHead}>Price</TableCell>
                <TableCell sx={style.tHead}>Quantity</TableCell>
                <TableCell sx={style.tHead}>Total</TableCell>
                <TableCell sx={style.tHead}>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartData.cartProducts.map((item, index) => (
                <CartProduct
                  key={index}
                  productData={item.productData}
                  quantity={item.quantity}
                  onPressTrash={() => removeItem(item.productData)}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Container>

      {cartData.cartProducts.length > 0 && (
        <Button
          variant="contained"
          sx={{
            position: "fixed",
            bottom: theme.spacing(2),
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "90%",
            justifyContent: "space-between",
            borderRadius: 1,
            boxShadow: 3,
            p: theme.spacing(1.5),
            backgroundColor: "#b7472a",
            "&:hover": {
              backgroundColor: "#8E2B1E",
            },
          }}
          endIcon={<ShoppingBagIcon />}
          onClick={onCheckOut}
          disabled={loading}
        >
          <Typography sx={{ fontFamily: "Spectral, serif", letterSpacing: 1 }}>
            {cartData.cartProducts.length} Proceed to Checkout
          </Typography>
        </Button>
      )}
    </>
  );
};

export default Cart;
const style = {
  heading: {
    fontWeight: 500,
    fontFamily: "Spectral, serif",
    fontSize: "20px",
  },
  body: {
    fontFamily: "Spectral, serif",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: 1.8,
    color: "rgba(0, 0, 0, 0.3)",
  },
  priceText: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#808080",
    textAlign: "center",
  },
  quantityInput: {
    width: "60px",
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
  deleteIcon: {
    color: "red",
  },
  tHead: {
    fontFamily: "Spectral, serif",
    color: "white",
    padding: "30px",
    fontSize: "20px",
    letterSpacing: 1,
  },
};
