import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { addtoCart, removeFromCart } from "../redux/cart/actions.ts";
import { getProductByID } from "../services/product/services.ts";
import { useParams, useNavigate } from "react-router-dom";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { ProductData } from "../services/product/types.ts";

const Product = () => {
  const [productData, setProductData] = useState<ProductData | null>(null);
  const theme = useTheme();
  const cartData = useSelector((state: StoreState) => state.cart);
  const [isProductInCart, setIsProductInCart] = useState<
    { productData: ProductData; quantity: number } | undefined
  >(undefined);
  const dispatch = useDispatch<StoreDispatch>();
  const navigate = useNavigate();
  const { productId } = useParams();

  const addTocart = (product: ProductData) => {
    dispatch(addtoCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {});
  };

  const removeQuantity = (product: ProductData) => {
    dispatch(removeFromCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {});
  };

  useEffect(() => {
    const index = cartData.cartProducts.findIndex(
      (item) => item.productData.id === Number(productId)
    );
    if (index > -1) setIsProductInCart(cartData.cartProducts[index]);
    else setIsProductInCart(undefined);
  }, [cartData.cartProducts, productId]);

  useEffect(() => {
    getProductByID(String(productId || ""), {}).then((data) => {
      if (data) {
        setProductData(data.data);
      }
    });
  }, [productId]);

  // Styles object
  const styles = {
    container: {
      padding: 2,
    },
    backButtonContainer: {
      position: "absolute",
      top: 20,
      left: 20,
    },
    backButtonText: {
      marginLeft: 2,
      marginTop: "10px",
      fontFamily: "'Spectral', serif",
      letterSpacing: 2,
    },
    gridContainer: {
      marginTop: 15,
    },
    imageBox: {
      width: "100%",
      height: 400,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: 1,
      borderColor: theme.palette.grey[400],
      borderRadius: 2,
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    productName: {
      fontFamily: "'Spectral', serif",
      fontWeight: "400",
      letterSpacing: 3,
    },
    productDetailHeader: {
      fontFamily: "'Spectral', serif",
      letterSpacing: 3,
      marginTop: "28px",
    },
    productDescription: {
      whiteSpace: "pre-wrap",
      marginTop: "25px",
      fontFamily: "'Spectral', serif",
      letterSpacing: 1,
    },
    cartActions: {
      display: "flex",
      alignItems: "center",
      mt: 2,
    },
    cartButton: {
      letterSpacing: 1,
      fontFamily: "'Spectral', serif",
      width: "180px",
      height: "50px",
      padding: 1,
      backgroundColor: "#b7472a",
      mt: 4,
      "&:hover": {
        backgroundColor: "#8E2B1E",
      },
    },
    stockStatus: {
      marginTop: "19px",
      fontSize: "17px",
      marginLeft: 1,
      fontWeight: "bold",
      letterSpacing: 1,
      fontFamily: "'Spectral', serif",
    },
    floatingCartButton: {
      position: "fixed",
      bottom: 16,
      right: 16,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: "50%",
      padding: 2,
    },
    rightContainer: {
      marginTop: "20px",
    },
  };

  return (
    <Container>
      <Box sx={styles.container}>
        <Box sx={styles.backButtonContainer}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.backButtonText}>
            Product Details
          </Typography>
        </Box>

        <Grid container spacing={4} sx={styles.gridContainer}>
          <Grid item xs={12} md={6}>
            <Box sx={styles.imageBox}>
              {productData && productData.images?.length > 0 && (
                <img
                  src={`https://bootleggersdistribution.net${productData.images[0].path}`}
                  alt={productData.name}
                  style={styles.image}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={styles.rightContainer}>
              <Typography variant="h5" sx={styles.productName}>
                {productData?.name}
              </Typography>

              <Typography variant="h6" sx={styles.productDetailHeader}>
                Product Detail
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={styles.productDescription}
              >
                {productData?.description}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={styles.stockStatus}
              >
                {productData?.quantity && productData?.quantity > 0
                  ? "Available in Stock"
                  : "Out Of Stock"}
              </Typography>
              {isProductInCart ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() => productData && removeQuantity(productData)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography>{isProductInCart.quantity}</Typography>
                  <IconButton
                    onClick={() => productData && addTocart(productData)}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => productData && addTocart(productData)}
                  sx={styles.cartButton}
                >
                  Add To Cart
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>

        <IconButton sx={styles.floatingCartButton}>
          <ShoppingCartIcon />
        </IconButton>
      </Box>
    </Container>
  );
};

export default Product;
