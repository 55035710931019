import React from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import { CategoryItem } from "../services/product/types.ts";
import { useNavigate } from "react-router-dom";

interface IProps {
  categories: CategoryItem[];
}

const HomeCategory: React.FC<IProps> = ({ categories }) => {
  console.log(categories);
  const navigate = useNavigate();
  const categoryImages = [
    {
      name: "GIN",
      link: "https://images.squarespace-cdn.com/content/v1/5b1beea896e76f6684f94330/1605451597626-ITXYO2J6T0VI1ID0OD31/Gilded-Gold-Gin-Bottle-glasses-Christmas-Scene.jpg",
    },
    {
      name: "RUM",
      link: "https://www.jiomart.com/images/product/original/rvmnctcjdw/vikijo-glass-for-whiskey-beer-and-rum-1000-ml-2-pcs-product-images-orvmnctcjdw-p601067644-6-202304291848.jpg?im=Resize=(420,420)",
    },
    {
      name: "VODKA",
      link: "https://sfycdn.speedsize.com/a8a6f2ac-3918-42a1-9fae-e87279b51b90/auvodka.co.uk/cdn/shop/files/Au_Vodka_Bottle_Glass-12_720x.jpg?v=1727778181",
    },
    {
      name: "WHISKEY",
      link: "https://m.media-amazon.com/images/I/8117GnbW-zL._AC_UF350,350_QL80_.jpg",
    },
    {
      name: "TEQUILA",
      link: "https://m.media-amazon.com/images/I/71pSb9OHpcL.jpg",
    },
    {
      name: "SANGRITA",
      link: "https://vineoh.com/cdn/shop/files/sonja-sangria-white.jpg?v=1717093359&width=416",
    },
    {
      name: "COGNAC",
      link: "https://static.vecteezy.com/system/resources/previews/036/469/695/large_2x/ai-generated-bottle-of-cognac-and-two-glasses-on-the-table-valentine-s-day-photo.jpg",
    },
    {
      name: "LIQUEURS",
      link: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb1v_uwJECIWtTR-P6uVHBf_bVuCNJHj-I7LQUjVJGRd3Ks3f7S_mI6ll7EqXRd9vnO0A&usqp=CAU",
    },
    {
      name: "VERMOUTH",
      link: "https://www.reservebar.com/dw/image/v2/BJBF_PRD/on/demandware.static/-/Sites-reservebarus-Library/default/dw59af6039/cocktail-lounge/editorials/first-letter-a/A%20Beginners%20Guide%20to%20Vermouth/20220425_Vermouth_-2_Editorial_Hero_Mobile.jpg?sw=1538",
    },
    {
      name: "ICE and DRY ICE",
      link: "https://img.etimg.com/thumb/msid-108237683,width-480,height-360,imgsize-32328,resizemode-75/what-is-dry-ice.jpg",
    },
    {
      name: "BRANDY",
      link: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR38cZNadD3k1zJakf_mGKzymUUXw79Q_OPOA&s",
    },
    {
      name: "Janitorial Supplies",
      link: "https://down-my.img.susercontent.com/file/10e93d7f63e9e3de57611074347aa043",
    },
    {
      name: "Bar / Party Supplies",
      link: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFzkGbSUwptjhQ0_hTs_OJzrye5-5yrM3PZw&s",
    },
    {
      name: "Champagne / Wine / Sparkling Liquors",
      link: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9GKWM2bBRSAf0pP1SPYbjwTT9afHtpTSB_2PhpdemWKZmuVY8UhVMLjybFc-x69KpztM&usqp=CAU",
    },
    {
      name: "JUICE and Bar SYRUPS",
      link: "https://localpalatemarketplace.com/cdn/shop/collections/cocktail-syrups-collection-image.jpg?v=1678461393&width=640",
    },
  ];
  const getImageCategory = (categoryName) => {
    const matchCategory = categoryImages.find(
      (item) => item.name === categoryName
    );
    return matchCategory ? matchCategory.link : "";
  };
  return (
    <Container
      sx={{
        paddingLeft: "15px",
        paddingRight: "15px",
        fontFamily: "Spectral, serif",
        fontSize: "16px",
        fontWeight: 300,
        color: "#000",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex", // Flexbox layout
          gap: 2, // Add space between items
          padding: 2,
          fontFamily: "Spectral, serif",
          fontSize: "16px",
          fontWeight: 300,
          color: "rgb(128, 128, 128)",
          lineHeight: "28.8px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "Spectral, serif",
            fontSize: "40px",
            textAlign: "center",
            letterSpacing: "1px",
            my: 1,
            marginTop: "5vh",
            color: "black",
          }}
        >
          Categories
        </Typography>
        <Box display={"flex"} sx={{ overflowX: "scroll", width: "80vw" }}>
          {categories.map((item, index) => (
            <Paper
              key={index}
              data-aos="fade-up"
              sx={{
                padding: 2,
                border: "none",
              }}
              onClick={() =>
                navigate(
                  `/category-product/${item.pCategoryId}/${encodeURIComponent(
                    item.name
                  )}`
                )
              }
              elevation={0}
            >
              <img
                src={getImageCategory(item.name)}
                alt={item.description}
                style={{
                  height: "130px",
                  width: "130px",
                  margin: "0 auto 20px",
                  borderRadius: "50%",
                  backgroundSize: "cover",
                  backgroundPosition: "50% 50%",
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  fontSize: "22px",
                  lineHeight: "33px",
                  color: "rgb(0, 0, 0)",
                  marginBottom: "8px",
                  fontFamily: "Spectral, serif",
                  fontWeight: 300,
                  textAlign: "center",
                  maxWidth: "130px",
                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflowed content
                  textOverflow: "ellipsis", // Show ellipsis when the text is truncated
                }}
              >
                {item.name}
              </Typography>
            </Paper>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default HomeCategory;
