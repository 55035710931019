import React from "react";
import { Button, IconButton, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreState } from "../redux/reduxStore.ts";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const CartButton: React.FC = () => {
  const navigate = useNavigate();
  const cartData = useSelector((state: StoreState) => state.cart);

  return (
    <>
      {cartData?.cartProducts?.length > 0 && (
        <Button
          variant="contained"
          sx={style.button}
          onClick={() => navigate("/cart")}
        >
          <Typography variant="button" sx={style.text}>
            {`${cartData?.cartProducts?.length} View Cart`}
          </Typography>
          <IconButton sx={style.iconBtn}>
            <ShoppingBagIcon sx={style.icon} />
          </IconButton>
        </Button>
      )}
    </>
  );
};

export default CartButton;

const style = {
  button: {
    position: "fixed",
    bottom: 16,
    right: 16,
    width: "calc(100% - 32px)",
    backgroundColor: "#b7472a",
    maxWidth: 300,
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#8E2B1E",
    },
  },
  text: {
    fontFamily: "Spectral, serif",
    letterSpacing: 1,
    fontSize: "16px",
  },
  iconBtn: {
    borderRadius: 2,
    padding: 1,
  },
  icon: { color: "white", fontSize: 40 },
};
