import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { userActions } from "../redux/user/slice";
import { ProductData } from "../services/product/types";
import { searchProductWithName } from "../services/product/services.ts";

import DrawerModal from "./DrawerModel.tsx";
import { FaFacebookF, FaInstagram, FaPhone, FaTwitter } from "react-icons/fa6";
import { LiaShoppingBagSolid } from "react-icons/lia";
import images from "../constant/images.ts";
import { TfiSearch } from "react-icons/tfi";

const Header = () => {
  const [value, setValue] = useState("");
  const [searchProduct, setSearchProduct] = useState<ProductData[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [searchIcon, setSearchIcon] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value.length > 0) {
        searchProductWithName(value, {}).then((data: any) => {
          if (data?.httpStatusCode === 200) {
            setSearchProduct(data.data);
          }
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <DrawerModal />
      <Box>
        <Box
          sx={{
            bgcolor: "black",
            display: "flex",
            alignItems: "center",
            paddingX: "70px",
            paddingY: "6px",
            fontFamily: "'Spectral', serif",
          }}
        >
          <Box sx={{ flex: 2 }}>
            <img
              src={images.logo}
              width={100}
              height={100}
              style={{ objectFit: "contain" }}
              alt="Logo"
            />
          </Box>

          {/* <Box sx={{ flex: 1, display: { xs: "none", lg: "flex" } }}>
            <TextField
              placeholder="Search"
              fullWidth
              value={value}
              onChange={(e) => setValue(e.target.value)}
              InputProps={{
                sx: {
                  color: "white",
                  paddingLeft: "20px",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "40px",
                  border: "1px solid rgba(255, 255, 255, 0.6)",
                  "&:hover": { borderColor: "rgba(255, 255, 255, 0.6)" },
                },
                inputProps: {
                  sx: { color: "white", padding: "10px 0" },
                },
              }}
            />
          </Box> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              flexDirection: { xs: "row-reverse", lg: "row" },
            }}
          >
            <List
              sx={{
                display: { xs: "none", lg: "flex" },
                alignItems: "center",
                fontSize: "20px",
                cursor:'pointer',
                color: "rgba(255, 255, 255, 0.6)",
                fontFamily: "'Spectral', serif",
                textTransform: "uppercase",
                "& .MuiListItemText-primary": {
                  fontFamily: "'Spectral', serif",
                },
              }}
            >
              <ListItem onClick={() => navigate("/")}>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem onClick={() => navigate("/all-categories")}>
                <ListItemText primary="Products " />
              </ListItem>
            </List>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <LiaShoppingBagSolid
                  size={20}
                  style={{ color: "#a23f25" ,cursor:"pointer"}}
                  onClick={() => navigate("/cart")}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },
                  marginLeft: "16px",
                }}
              >
                <TfiSearch
                  onClick={() => setSearchIcon(!searchIcon)}
                  style={{ color: "white", cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {searchIcon && (
          <Box sx={{ paddingX: { xs: "20px", md: "70px" } }}>
            <TextField
              placeholder="Search"
              fullWidth
              value={value}
              onChange={(e) => setValue(e.target.value)}
              InputProps={{
                sx: {
                  color: "white",
                  paddingLeft: "20px",
                  backgroundColor: "black",
                  borderRadius: "70px",
                },
              }}
            />
          </Box>
        )}

        {toggle && (
          <Box
            sx={{
              bgcolor: "black",
              color: "rgba(255, 255, 255, 0.6)",
              paddingX: "50px",
              paddingY: "20px",
              display: { xs: "block", lg: "none" },
            }}
          >
            <List
              sx={{
                textTransform: "uppercase",
                "& .MuiListItemText-primary": {
                  fontFamily: "'Spectral', serif",
                },
              }}
            >
              <ListItem onClick={() => navigate("/")}>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem onClick={() => navigate("/all-categories")}>
                <ListItemText primary="Products" />
              </ListItem>
            </List>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Header;
