import React from "react";
import {
  TableCell,
  TableRow,
  Box,
  Typography,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { StoreDispatch } from "../redux/reduxStore.ts";
import { ProductData } from "../services/product/types.ts";
import { addtoCart, removeFromCart } from "../redux/cart/actions.ts";

interface IProps {
  isCheckOut?: boolean;
  productData: ProductData;
  quantity: number;
  onPressTrash?: () => void;
}

const CartProduct: React.FC<IProps> = ({
  isCheckOut,
  productData,
  quantity,
  onPressTrash,
}) => {
  const dispatch = useDispatch<StoreDispatch>();

  const addTocart = (product: ProductData) => {
    dispatch(addtoCart({ productData: product, quantity: 1 })).unwrap();
  };

  const removeQuantity = (product: ProductData) => {
    dispatch(removeFromCart({ productData: product, quantity: 1 }));
  };

  return (
    <TableRow>
      {/* Product Image and Name */}
      <TableCell>
        <Box display="flex" alignItems="center">
          <img
            src={`https://bootleggersdistribution.net${productData?.images[0]?.path}`}
            alt={productData.name}
            style={{ width: "70px", height: "80px", marginRight: "10px" }}
          />
          <Box>
            <Typography variant="h6" sx={style.heading}>
              {productData.name}
            </Typography>
            <Typography variant="body2" sx={style.body}>
              {productData.description}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* Product Price */}
      <TableCell>
        <Typography variant="body1" sx={style.priceText}>
          ${productData.price}
        </Typography>
      </TableCell>

      {/* Quantity Field */}
      <TableCell>
        <TextField
          type="number"
          value={quantity}
          InputProps={{ inputProps: { min: 1, max: 10 } }}
          sx={style.quantityInput}
          onChange={(e) =>
            dispatch(
              addtoCart({ productData, quantity: parseInt(e.target.value) })
            )
          }
        />
      </TableCell>

      {/* Total Price */}
      <TableCell>
        <Typography variant="body1" sx={style.priceText}>
          ${(productData.price * quantity).toFixed(2)}
        </Typography>
      </TableCell>

      {/* Delete Button */}
      <TableCell>
        <IconButton onClick={onPressTrash}>
          <DeleteIcon sx={style.deleteIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CartProduct;

const style = {
  heading: {
    fontWeight: 500,
    fontFamily: "Spectral, serif",
    fontSize: "20px",
  },
  body: {
    fontFamily: "Spectral, serif",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: 1.8,
    color: "rgba(0, 0, 0, 0.3)",
  },
  priceText: {
    fontFamily: "Spectral, serif",
    fontSize: "18px",
    fontWeight: 10,
    color: "#808080",
    textAlign: "center",
  },
  quantityInput: {
    width: "60px",
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
  deleteIcon: {
    color: "red",
  },
  tHead: {
    fontFamily: "Spectral, serif",
    color: "white",
    padding: "30px",
    fontSize: "20px",
    letterSpacing: 1,
  },
};
