import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import images from "../constant/images.ts";
import { isValidEmail } from "../utils/helpers.ts";

const ForgotPassword = () => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const onSubmit = () => {
    if (!isValidEmail(email)) {
      setEmailError("Email is invalid.");
    } else {
      alert("A password reset email has been sent. Please check your email.");
    }
  };
  

  return (
    <Container sx={styles.container}>
      <Box sx={styles.box}>
        <Box
          component="img"
          src={images.logo}
          alt="Logo"
          sx={styles.logo}
        />
        <Typography variant="h4" gutterBottom sx={styles.typography}>
          Forgot Password
        </Typography>
        <Typography variant="body1" paragraph sx={{ ...styles.typography, lineHeight: "25px" }}>
          Lost your password? Please enter your email address. You will receive
          a link to create a new password via email.
        </Typography>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
          fullWidth
          margin="normal"
          sx={styles.textField}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          sx={styles.button}
        >
          Reset Password
        </Button>
        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={styles.typography}>
            Don't have an account yet?{" "}
            <Button sx={styles.signUpButton}>
              Sign Up
            </Button>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
const styles = {
  container: {
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    justifyContent: "center",
    height: "100vh", 
    backgroundColor: "#f0f2f5", 
  },
  box: {
    padding: 4,
    borderRadius: 5,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", 
    backgroundColor: "white", 
    width: { xs: '90%', sm: '400px' },
  },
  logo: {
    width: "100px", 
    height: "auto",
    mb: 2,
    display: "block", 
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    mt: 2,
    borderRadius: 1,
    fontFamily: "spectral, serif", 
    letterSpacing: 1,
    backgroundColor: "#b7472a",
    "&:hover": {
      backgroundColor: "#8E2B1E",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
    },
  },
  signUpButton: {
    textTransform: "none",
    color: "#092238", 
    fontFamily: "spectral, serif", 
    letterSpacing: 1,
  },
  typography: {
    fontFamily: "spectral, serif", 
    letterSpacing: 1,
  },
};