import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Skeleton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { StoreDispatch } from "../redux/reduxStore.ts";
import { addtoCart, removeFromCart } from "../redux/cart/actions.ts";
import {
  getAllProduct,
  getProductsByCategoryId,
} from "../services/product/services.ts";
import { CategoryProductItem, ProductData } from "../services/product/types.ts";
import ProductCardHorizontal from "../commponents/ProductCardHorizontal.tsx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CartButton from "../commponents/CartButton.tsx";

const CategoryProduct: React.FC = () => {
  const { id, name } = useParams<{ id: string; name: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<StoreDispatch>();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<CategoryProductItem[]>([]);

  const addTocart = (product: ProductData) => {
    dispatch(addtoCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {
        alert(message);
      });
  };

  const removeQuantity = (product: ProductData) => {
    dispatch(removeFromCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {
        alert(message);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (id === "allProducts") {
      getAllProduct({}).then((data: any) => {
        if (data) setProducts(data.data);
        setLoading(false);
      });
    } else {
      if (id)
        getProductsByCategoryId(id, {})
          .then((data: any) => {
            if (data) setProducts(data.data);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoading(false);
          });
    }
  }, [id]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: { xs: 70, sm: 90 },
          boxShadow: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          backgroundColor: "#b7472a",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: { xs: 20, sm: 30 },
            left: { xs: 15, sm: 20 },
            color: "white",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: "white",
            fontFamily: "'Spectral', serif",
            fontSize: { xs: "1.2rem", sm: "1.5rem" },
          }}
        >
          {name}
        </Typography>
      </Box>

      <Container sx={{ paddingBottom: 0, backgroundColor: "transparent" }}>
        {loading ? (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={100}
            animation="wave"
            sx={{ borderRadius: 1, margin: 4 }}
          />
        ) : products.length === 0 ? (
          <Typography
            align="center"
            sx={{ mt: 2, fontFamily: "'Spectral', serif" }}
          >
            No Products Found
          </Typography>
        ) : (
          <Box>
            <Box
              sx={{
                display: { xs: "none", md: "grid" },
                gridTemplateColumns: {
                  xs: "none",
                  md: "3fr 1fr 1fr 1fr 1fr",
                },
                backgroundColor: "#b7472a",
                padding: { xs: "10px", sm: "20px", md: "30px" },
                marginTop: "30px",
              }}
            >
              <Box
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                  gridColumn: { xs: "1 / -1", sm: "auto" },
                }}
              >
                Product
              </Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                  marginLeft: { sm: "30px", md: "90px" },
                  gridColumn: { xs: "1 / -1", sm: "auto" },
                }}
              >
                Price
              </Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                  marginLeft: { sm: "30px", md: "60px" },
                  gridColumn: { xs: "1 / -1", sm: "auto" },
                }}
              >
                Quantity
              </Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                  marginLeft: { sm: "30px", md: "50px" },
                  gridColumn: { xs: "1 / -1", sm: "auto" },
                }}
              >
                Total
              </Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                  marginLeft: { sm: "30px", md: "70px" },
                  gridColumn: { xs: "1 / -1", sm: "auto" },
                }}
              >
                Action
              </Box>
            </Box>

            {/* Product Table */}
            <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
              <Table
                sx={{
                  minWidth: 650,

                  overflowX: "auto",
                  "& td, & th": { padding: { xs: "6px", sm: "12px" } },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {products.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <ProductCardHorizontal
                          product={item}
                          src={`https://bootleggersdistribution.net${item?.images[0]?.path}`}
                          onPressAdd={() => addTocart(item)}
                          onPressDec={() => removeQuantity(item)}
                          onDelete={() => removeQuantity(item)}
                          checked={false}
                          onCheck={() => {}}
                          onPress={() => {}}
                        />
                      </TableCell>

                      <TableCell align="center">
                        <IconButton
                          sx={{
                            borderRadius: "50%",
                            width: { xs: 40, sm: 50 },
                            height: { xs: 40, sm: 50 },
                            color: "white",
                            backgroundColor: "#b7472a",
                            fontSize: "0.875rem",
                            "&:hover": {
                              backgroundColor: "#8E2B1E",
                            },
                          }}
                          onClick={() => addTocart(item)}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Container>
      <CartButton />
    </>
  );
};

export default CategoryProduct;
