import React from "react";
import { Container, Typography, Box } from "@mui/material";

const AboutUs = () => {
  return (
    <Container maxWidth="md" sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>

      <Typography paragraph>
        Welcome to Bootleggers Distribution, your premier wholesale supplier for
        liquor and party essentials in the Wisconsin area! Located at 1530 W
        National Ave in Milwaukee, WI 53204, we specialize in providing licensed
        retailers and bars with a wide selection of high-quality liquors,
        ensuring that you have the best products to serve your customers.
      </Typography>

      <Typography paragraph>
        In addition to our extensive liquor offerings, we cater to everyone with
        a fantastic range of party supplies and dry ice products. Whether you're
        planning a lively event, stocking up for a celebration, or simply need
        dry ice for a special occasion, we've got you covered.
      </Typography>

      <Typography paragraph>
        For your convenience, we offer the Bootleggers Distribution app,
        available for free on both Android and iOS. This easy-to-use app allows
        you to place orders online anytime, making your purchasing experience
        smooth and efficient.
      </Typography>

      <Typography paragraph>
        At Bootleggers Distribution, we pride ourselves on our exceptional
        customer service and commitment to quality. Our knowledgeable team is
        here to help you find exactly what you need, whether you’re a business
        owner or planning your next gathering.
      </Typography>

      <Typography paragraph>
        Join us in celebrating great products and unforgettable moments—your
        one-stop shop for all your liquor and party supply needs!
      </Typography>
    </Container>
  );
};

export default AboutUs;
