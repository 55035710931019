import React from "react";
import { Container, Typography, Box } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions - Bootleggers Distribution LLC
      </Typography>

      <Typography paragraph>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions")
        carefully before using the Liquor Wholesale Application (the "Service")
        operated by Bootleggers Distribution LLC ("us", "we", or "our").
      </Typography>

      <Typography paragraph>
        Your access to and use of the Service is conditioned on your acceptance
        of and compliance with these Terms. These Terms apply to all visitors,
        users, and others who access or use the Service.
      </Typography>

      <Typography paragraph>
        By accessing or using the Service you agree to be bound by these Terms.
        If you disagree with any part of the terms then you may not access the
        Service.
      </Typography>

      {/* Section 1: Eligibility */}
      <Box mb={2}>
        <Typography variant="h6">1. Eligibility</Typography>
        <Typography paragraph>
          1.1. You must be at least 21 years old to use this Service. By
          accessing or using the Service, you represent and warrant that you are
          at least 21 years old.
        </Typography>
        <Typography paragraph>
          1.2. Additionally, this Service is only available to businesses with a
          valid liquor license within the state of Wisconsin.
        </Typography>
      </Box>

      {/* Section 2: Ordering and Pickup */}
      <Box mb={2}>
        <Typography variant="h6">2. Ordering and Pickup</Typography>
        <Typography paragraph>
          2.1. The Service allows users to place orders for liquor products for
          pickup from designated stores.
        </Typography>
        <Typography paragraph>
          2.2. Orders must be placed by individuals who are at least 21 years
          old or by actively licensed businesses.
        </Typography>
        <Typography paragraph>
          2.3. Payment for orders will be made upon pickup from the designated
          store. Online payment options are not available.
        </Typography>
      </Box>

      {/* Section 3: Compliance with Laws */}
      <Box mb={2}>
        <Typography variant="h6">3. Compliance with Laws</Typography>
        <Typography paragraph>
          3.1. By using the Service, you agree to comply with all applicable
          laws and regulations regarding the purchase, sale, and consumption of
          alcoholic beverages in the state of Wisconsin.
        </Typography>
      </Box>

      {/* Section 4: Account Registration */}
      <Box mb={2}>
        <Typography variant="h6">4. Account Registration</Typography>
        <Typography paragraph>
          4.1. In order to use the Service, you may be required to create an
          account with us. You must provide accurate and complete information
          and keep your account information updated. You are solely responsible
          for maintaining the confidentiality of your account and password and
          for restricting access to your account. You agree to accept
          responsibility for all activities that occur under your account.
        </Typography>
      </Box>

      {/* Section 5: Limitation of Liability */}
      <Box mb={2}>
        <Typography variant="h6">5. Limitation of Liability</Typography>
        <Typography paragraph>
          5.1. We shall not be liable for any damages, liabilities, losses, or
          expenses arising out of or in connection with the use or inability to
          use the Service.
        </Typography>
      </Box>

      {/* Section 6: Indemnification */}
      <Box mb={2}>
        <Typography variant="h6">6. Indemnification</Typography>
        <Typography paragraph>
          6.1. You agree to indemnify and hold harmless Bootleggers Distribution
          LLC and its affiliates, officers, employees, agents, and licensors
          from and against any and all claims and expenses, including attorneys'
          fees, arising out of your use of the Service, including but not
          limited to your violation of these Terms.
        </Typography>
      </Box>

      {/* Section 7: Governing Law */}
      <Box mb={2}>
        <Typography variant="h6">7. Governing Law</Typography>
        <Typography paragraph>
          7.1. These Terms shall be governed and construed in accordance with
          the laws of the state of Wisconsin, without regard to its conflict of
          law provisions.
        </Typography>
      </Box>

      {/* Section 8: Changes */}
      <Box mb={2}>
        <Typography variant="h6">8. Changes</Typography>
        <Typography paragraph>
          8.1. We reserve the right, at our sole discretion, to modify or
          replace these Terms at any time. If a revision is material, we will
          try to provide at least 30 days' notice prior to any new terms taking
          effect. What constitutes a material change will be determined at our
          sole discretion.
        </Typography>
      </Box>

      {/* Section 9: Contact Us */}
      <Box mb={2}>
        <Typography variant="h6">9. Contact Us</Typography>
        <Typography paragraph>
          9.1. If you have any questions about these Terms, please contact us at
          main.bootleggers@gmail.com.
        </Typography>
      </Box>

      <Typography paragraph>
        By accessing or using the Service, you agree to be bound by these Terms.
        If you do not agree to any part of the terms, then you may not access
        the Service.
      </Typography>
    </Container>
  );
};

export default TermsAndConditions;
