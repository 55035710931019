import React from "react";
import { Box, Typography } from "@mui/material";
import { ProductData } from "../services/product/types";
import { useTheme } from "@mui/material/styles";

type IProps = {
  item: {
    productData: ProductData;
    quantity: number;
  };
};

const OrderSummaryCard: React.FC<IProps> = ({ item }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        my: 2,
      }}
    >
      <Box sx={{ width: "70%" }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500 ,fontSize:{sm:18,md:23} , fontFamily: "Spectral, serif",  }}
          noWrap
        >
          {item.quantity}X <span>{item.productData.name}</span>
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: 400, color: theme.palette.grey[600] , fontFamily: "Spectral, serif", }}
        >
          {item.productData.description}
        </Typography>
      </Box>
      {/* Uncomment if price is needed */}
      {/* <Typography>${(item.quantity * item.productData.price).toFixed(2)}</Typography> */}
    </Box>
  );
};

export default OrderSummaryCard;
